<template>
  <button
    class="value-toggle-button"
    :class="{
      'value-toggle-button--alert': isAlert,
      'value-toggle-button--active': currentValue === activeValue,
    }"
    @click="setValue"
  >
    <BaseSVG :src="require(`@/assets/${icon}-icon.svg`)" />
  </button>
</template>

<script>
import BaseSVG from "@/components/base/BaseSVG";
export default {
  name: "ValueToggleButton",
  components: {
    BaseSVG,
  },
  props: {
    activeValue: {
      type: Number,
      required: true,
      default: 0,
    },
    currentValue: {
      type: Number,
      default: null,
    },
    isAlert: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: "check-mark",
    },
  },
  methods: {
    setValue() {
      this.$emit("on-toggle", { value: this.activeValue });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.value-toggle-button {
  display: inline-flex;
  border: none;
  font-family: inherit;
  margin: 0;
  padding: 0;
  background: transparent;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: solid 1px $disabled;
  cursor: pointer;

  svg {
    fill: $disabled;
    width: 16px;
  }

  &--active {
    border: none;
    background: $fade-green;

    svg {
      fill: $green;
    }

    &.value-toggle-button--alert {
      background: $fade-red;

      svg {
        fill: $red;
      }
    }
  }
}
</style>