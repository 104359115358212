<template>
  <div class="value-toggle-button-group">
    <ValueToggleButton
      :activeValue="1"
      :currentValue="value"
      :isAlert="onlyNegative"
      :icon="icon"
      @on-toggle="onValueSelected"
    />
    <ValueToggleButton
      v-if="!onlyPositive && !onlyNegative"
      :activeValue="0"
      :currentValue="value"
      :isAlert="true"
      :icon="'thumbs-down'"
      @on-toggle="onValueSelected"
    />
  </div>
</template>

<script>
import ValueToggleButton from "@/components/ValueToggleButton";
export default {
  name: "ValueToggleButtonGroup",
  props: {
    value: {},
    onlyPositive: {
      type: Boolean,
      required: false,
      default: false,
    },
    onlyNegative: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ValueToggleButton,
  },
  computed: {
    icon() {
      if (this.onlyPositive) {
        return "check-mark";
      }

      if (this.onlyNegative) {
        return "warning";
      }

      return "thumbs-up";
    },
  },
  methods: {
    onValueSelected(val) {
      let defaultValue = null;
      if (this.onlyPositive || this.onlyNegative) {
        defaultValue = 0;
      }

      this.$emit("input", this.value === val.value ? defaultValue : val.value);
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";

.value-toggle-button-group {
  display: flex;
  align-items: center;

  .value-toggle-button {
    margin-left: 0.5rem;
  }
}
</style>