import { getInstance } from "@/auth";

export const user = {
    computed: {
        $_user_data() {
            const instance = getInstance();
            return instance.user;
        },
        $_user_name() {
            if (this.$_user_data) {
                return this.$_user_data.name || null;
            }

            return null;
        },
        $_user_email() {
            if (this.$_user_data) {
                return this.$_user_data.email || null;
            }

            return null;
        },
    }
}