export const callReview = {
  filters: {
    reviewerName(value) {
      const value_parts = value.split("@");
      return value_parts[0];
    },
  },
  computed: {
    review() {
      return this.$store.getters["conversions/review"];
    },
    reviewLoading() {
      return this.$store.getters["conversions/review_loading"];
    },
    reviewEmpty() {
      return this.getReviewEmpty(this.review);
    },
    reviewPending() {
      return this.getReviewPending(this.review);
    },
    parents() {
      let list = [];
      if (this?.review?.responses) {
        this.review.responses.forEach((response) => {
          let index = list.findIndex((item) => {
            return item.heading === response.parent_name;
          });

          if (index === -1) {
            list.push({
              heading: response.parent_name,
              responses: [],
            });

            index = list.length - 1;
          }

          list[index].responses.push(response);
        });
      }

      return list;
    },
    totalScore() {
      return this.getTotalScore(this.review);
    },
    scoreContext() {
      return this.getScoreContext(this.review);
    },
  },
  methods: {
    checkInfractions(review) {
      if (!this.getReviewPending(review)) {
        return (
          review.medical_infraction ||
          review.language_infraction ||
          review.other_infraction
        );
      }

      return false;
    },
    getTotalScore(review) {
      if (review?.responses) {
        // Check for infractions
        if (this.checkInfractions(review)) {
          return 0;
        }

        // Get the total number of positive responses
        let totalPlus = review.responses.reduce((total, response) => {
          if (response.response == 1) {
            return (total += response.response);
          }

          return total;
        }, 0);

        // Get the total number of negative responses
        let totalMinus = review.responses.reduce((total, response) => {
          if (response.response == 0) {
            return total + 1;
          }

          return total;
        }, 0);

        // Calculate total number of responses (non null values)
        let total = totalPlus + totalMinus;

        // If we have any responses
        // Get percentage of positive responses
        // Return base 10 score
        if (total > 0) {
          return Math.round((totalPlus / total) * 10);
        }
      }

      return 0;
    },
    getScoreContext(review) {
      if (!review || this.getReviewEmpty(review)) {
        return null;
      }

      const score = this.getTotalScore(review);
      return this.getScoreContextRaw(score);
    },
    getScoreContextRaw(score) {
      if (score < 4) {
        return "poor";
      } else if (score >= 4 && score < 7) {
        return "fair";
      } else if (score >= 7) {
        return "good";
      }

      return null;
    },
    getReviewPending(review) {
      return !review || typeof review.responses === "undefined";
    },
    getReviewEmpty(review) {
      if (!review || !review.responses) {
        return true;
      }
      let is_empty = true;

      review.responses.forEach((response) => {
        if (response.response !== null) {
          is_empty = false;
        }
      });

      if (
        review.medical_infraction ||
        review.language_infraction ||
        review.other_infraction
      ) {
        is_empty = false;
      }

      return is_empty;
    },
  },
};
