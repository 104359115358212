import { render, staticRenderFns } from "./CallReviewSidebar.vue?vue&type=template&id=0e8f6c39&scoped=true&"
import script from "./CallReviewSidebar.vue?vue&type=script&lang=js&"
export * from "./CallReviewSidebar.vue?vue&type=script&lang=js&"
import style0 from "./CallReviewSidebar.vue?vue&type=style&index=0&id=0e8f6c39&lang=scss&scoped=true&"
import style1 from "./CallReviewSidebar.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e8f6c39",
  null
  
)

export default component.exports