<template>
  <Sidebar :active="active" @dismiss="onDismiss">
    <Loading :active="loading" :fullscreen="false" />
    <template v-slot:header>
      <h2>
        Call Review
        <span
          v-if="reviewEmpty"
          class="create-comment-modal__skip"
          @click="callReviewActive = false"
          >Skip</span
        >
      </h2>
    </template>

    <template v-if="!reviewPending">
      <div class="review-stats">
        <div v-if="review.email" class="review-stat">
          <div class="review-stat__header">Reviewer</div>
          <div class="review-stat__value">
            {{ review.email | reviewerName }}
          </div>
        </div>
        <div v-if="review.nurse_id" class="review-stat">
          <div class="review-stat__header">Nurse</div>
          <div class="review-stat__value">{{ nurse }}</div>
        </div>
      </div>
      <!-- START CALL INFRACTIONS -->
      <div class="review-category">
        <div class="review-category__heading">Call Infractions</div>
        <div class="review-category__items">
          <div class="review-category__item">
            <label>Medical Infraction</label>
            <ValueToggleButtonGroup
              v-model="review.medical_infraction"
              :onlyNegative="true"
            />
          </div>
          <div class="review-category__item">
            <label>Language Infraction</label>
            <ValueToggleButtonGroup
              v-model="review.language_infraction"
              :onlyNegative="true"
            />
          </div>
          <div class="review-category__item">
            <label>Other Infraction</label>
            <ValueToggleButtonGroup
              v-model="review.other_infraction"
              :onlyNegative="true"
            />
          </div>
        </div>
      </div>

      <!-- START CALL REVIEW OPTIONS -->
      <div
        class="review-category"
        :class="{ 'review-category--disabled': checkInfractions(review) }"
        v-for="(category, index) in parents"
        :key="`category-${index}`"
      >
        <div class="review-category__heading">{{ category.heading }}</div>
        <div class="review-category__items">
          <div
            class="review-category__item"
            v-for="(response, i) in category.responses"
            :key="`review-${i}`"
          >
            <label>{{ response.name }}</label>
            <ValueToggleButtonGroup v-model="response.response" />
          </div>
        </div>
      </div>
    </template>

    <div class="review-notes">
      <TextareaField label="Add a Comment" v-model="review.comment" />
    </div>

    <template v-slot:footer>
      <div class="review-score" :class="[`review-score--${scoreContext}`]">
        <div class="review-score__label">Total Score</div>
        <div class="review-score__result">
          <div class="review-score__context">{{ scoreContext }}</div>
          <div class="review-score__number">{{ totalScore }}</div>
        </div>
      </div>
      <button class="button" @click="saveReview">Save Review</button>
    </template>
  </Sidebar>
</template>

<script>
import Loading from "@/components/Loading";
import Sidebar from "@/components/Sidebar";
import ValueToggleButtonGroup from "@/components/ValueToggleButtonGroup";
import TextareaField from "@/components/fields/TextareaField";
import { callReview } from "@/mixins/callReview";
import { nurses } from "@/mixins/nurses";
import { user } from "@/mixins/user";

export default {
  name: "CallReviewSidebar",
  props: ["action_id", "active"],
  mixins: [callReview, nurses, user],
  components: {
    Loading,
    Sidebar,
    ValueToggleButtonGroup,
    TextareaField,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    nurse() {
      if (this.review && this.review.nurse_id) {
        return this.$_nurses_getNurseNameById(this.review.nurse_id);
      }

      return "-";
    },
  },
  methods: {
    onDismiss() {
      this.$emit("dismiss");
    },
    saveReview() {
      let reviewPayload = {
        ...this.review,
      };

      // Set the user's info
      reviewPayload.email = this.$_user_email;
      reviewPayload.user_name = this.$_user_name;

      this.$store
        .dispatch("conversions/saveConversionActionReview", {
          id: this.action_id,
          review: reviewPayload,
        })
        .then((res) => {
          if (res.data) {
            this.$emit("review-saved", res.data);
          }
        });
    },
  },
  watch: {
    action_id() {
      const id = this.action_id;
      this.$store.dispatch("conversions/getConversionActionReview", {
        id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/colors";
@import "@/scss/call_review";
</style>

<style lang="scss">
@import "@/scss/colors";
.review-notes {
  .input-wrapper {
    label {
      display: block;
      margin-bottom: 1rem;
    }

    textarea {
      border: solid 1px $disabled;
      border-radius: 4px;
      height: 125px;
      padding: 0.5rem;
      width: 100%;
    }
  }
}
</style>